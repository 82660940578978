import React from 'react';
import './Banner.css';

const Banner = () => {
  return (
    <footer className="banner">
      <a href='https://www.gov.uk/tax-free-childcare' target="_blank" rel="noopener noreferrer">
      <div className="banner-content">
        <h3>We accept tax-free government funding for 9 months and onwards</h3>
      </div>
      </a>
    </footer>
  );
};

export default Banner;
